<template>
  <div id="day-edit-container">
    <Card class="mb-1">
      <template v-slot:header>
        Редактировать образовательный день
      </template>
      <div class="row">
        <div class="col-12">
          <div class="col-sm-12 mb-3">
            <div class="input-label">
              <span>Статус</span>
            </div>
            <Select
              :data="statuses"
              :selected="selectedStatus"
              @change="selectedStatus = $event"
            >
              <template v-slot:placeholder="{ selectedItem }">
                <div
                  v-if="selectedItem !== null && selectedItem !== undefined"
                  class="d-flex flex-row align-items-center"
                >
                  <div>{{ selectedItem.name }}</div>
                </div>
              </template>
              <template v-slot:default="{ item }">
                <div class="d-flex flex-row align-items-center text-">
                  <div class="w-75">
                    {{ item.name }}
                  </div>
                </div>
              </template>
            </Select>
          </div>

          <div class="input-label">
            <span>Курс</span>
          </div>
          <Input
            :value="selectedCourse ? selectedCourse.name : ''"
            :disabled="true"
          />
         
          <Input
            v-model="day.name"
            placeholder="Тема №1"
            label="Название дня"
            class="mt-3"
          />

          <div>
            <div class="input-label">
              <label>Приветственное изображение дня</label>
            </div>

            <Dropzone
              v-slot:default="{ files , removeFileByIndex, active, browse }"
              ref="dropzone"
              class="mb-3"
              @change="day_images = $event"
            />
          </div>

          <div v-if="day_images && day_images.length > 0">
            <div class="mt-4">
              Добавленные файлы:
            </div>
            <div 
              v-for="file in day_images"
              :key="file._id"
            >
              <div class="input-label">
                <label>{{ file.name }}</label>
              </div>
              <img 
                style="max-width: 100px;"
                :src="file.src"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="input-group mt-3">
        <Button 
          type="outline-light-bg-success"
          @click="saveDay"
        >
          Обновить
        </Button>
      </div>
    </Card>

    <Card
      v-if="tasks && tasks.length > 0"
      class="mb-1"
    >
      <template
        v-slot:header
        class="mb-3"
      >
        Задания
      </template>
      <Accordion :always-one="true">
        <AccordionItem
          v-for="(task, tindex) in tasks"
          :key="tindex"
        >
          <b slot="default">{{ tindex + 1 }}) {{ getTaskTypeName(task.type) }}</b>
          <Badge
            class="ms-3"
            type="success"
            inverse
          >
            {{ task.point }} баллов
          </Badge>
          <Button
            type="outline-light-bg-default"
            class="mx-1 px-1"
            style="float:right;"
            @click="taskUp(task)"
          >
            <i class="bi bi-arrow-up" />
          </Button>
          <Button
            type="outline-light-bg-default"
            class="mx-1 px-1"
            style="float:right;"
            @click="taskDown(task)"
          >
            <i
              class="bi bi-arrow-down"
              style="float:right;"
            />
          </Button>

          <div slot="body">
            <EditTask 
              :ref="'EditTaskComponent_' + task._id"
              :task="task"
              @delete="deleteTaskEvent = $event"
            />
          </div>
        </AccordionItem>
      </Accordion>
    </Card>

    <Card class="mb-5">
      <template v-slot:header>
        Новое задание
      </template>
      <div v-if="taskCreating">
        <NewTask 
          ref="NewTaskComponent"
          :day-id="day.id"
          @change="newTaskItem = $event"
        />
      </div>
      <div v-else>
        <button
          class="btn btn-info"
          @click="newTask"
        >
          Добавить
        </button>
      </div>
    </Card>
  </div>
</template>

<script>
import DayService from "../../services/DayService";
import TaskService from "../../services/TaskService";
import CourseService from "../../services/CourseService";
import swal from "sweetalert";
import {getBase64Url, scrollTo, urltoFile} from "../../helpers/helpers";

export default {
  name: "EditDayForms",

  data() {
    return {
      day: {
        id: "",
        course_id: "",
        name: "",
      },
      courses: [],
      tasks: [],
      day_images: [],
      selectedCourse: null,
      taskCreating: false,
      statuses: DayService.getStatuses(),
      selectedStatus: DayService.STATUS_ACTIVE,
      newTaskItem: null,
      deleteTaskEvent: null
    };
  },

  watch: {
    day_images: function(images){
      let vm = this;
      images.forEach(image => {
        if (image.src) {
          return;
        }

        getBase64Url(image, function(base64Data){
            image.src = base64Data;
            vm.$forceUpdate();
        });
      });
    },
    $route (to, from){
      this.clean();
      this.init();
      this.$forceUpdate();
    },
    newTaskItem: function(newTask) {
      this.tasks.push(newTask);
      newTask = null;
      this.taskCreating = false;   
    },
    deleteTaskEvent: function(task) {
      const vm = this;
      vm.tasks = vm.tasks.filter((item) => {
        return item._id !== task._id;
      });
    }
  },

  async mounted() {
    const self = this;
    await this.init();
    document.getElementById('day-edit-container').addEventListener(
      'focus',
      async (el) => {
        if (el.target !== null && el.target !== undefined) {
          await self.$nextTick();
          self.scrollTo(el.target, 'center');
        }
      },
      true
    );
  },

  methods: {
    async saveDay() {
      if (!this.selectedCourse) {
        swal("Ошибка!", "Поле «Курс» не заполнено.", "warning");
        return;
      }

      if (!this.day.name) {
        swal("Ошибка!", "Поле «Название дня» не заполнено.", "warning");
        return;
      }
      
      if (!this.day_images || this.day_images.length === 0) {
        swal("Ошибка!", "Поле «Приветственное изображение дня» не заполнено.", "warning");
        return;
      }

      let data = new FormData()
      data.append('course_id', this.selectedCourse.id);
      data.append('name', this.day.name);
      data.append('status', this.selectedStatus.id);

      if (this.day_images[0] instanceof File) {
        data.append('image', this.day_images[0]);
      } else {
        const day_image = await urltoFile(this.day_images[0].src, this.day_images[0].name, this.day_images[0].contentType);
        data.append('image', day_image);
      }

      DayService.update(this.day.id, data)
        .then(response => {
          swal("Успех!", "День обновлен", "success");
        })
        .catch(e => {
          swal("Ошибка!", "Что то пошло не так", "error");
          console.log(e.toJSON());
        });
    },

    async newTask() {
        this.taskCreating = true;
        await this.$nextTick();
        this.$refs.NewTaskComponent.clean();
        const saveTaskBtn = this.$refs.NewTaskComponent.$refs.saveTask;
        this.scrollTo(saveTaskBtn);
    },

    getTaskTypeName(type) {
      return TaskService.getTaskTypeName(type);
    },

    scrollTo(element, block) {
      scrollTo(element, block);
    },

    async updateTasks()
    {
      const day = await DayService.get(this.$route.params.id);
      this.tasks = [];
      await this.$nextTick();

      for (const key in day.tasks) {
        await this.$set(this.tasks, key, day.tasks[key]);
      }

      await this.$forceUpdate();
    },

    async taskUp(currentTask) {
      const currTaskIndex = this.tasks.findIndex((task) => task._id == currentTask._id);
      const prevTaskIndex = currTaskIndex - 1;
      const prevTask = this.tasks[prevTaskIndex];
      const vm = this;

      if (prevTask) {
        const currNumQueue = currentTask.num_in_queue;
        const prevNumQueue = prevTask.num_in_queue;

        if (currNumQueue == prevNumQueue) {
          if (currNumQueue == 0) {
            prevTask.num_in_queue += 1;
          } else {
            currentTask.num_in_queue -= 1;
          }
        } else {
          currentTask.num_in_queue = prevNumQueue;
          prevTask.num_in_queue = currNumQueue;
        }

        const editTaskComponent = vm.$refs['EditTaskComponent_' + currentTask._id][0];
        await editTaskComponent.updateTask(currentTask, true);
        await editTaskComponent.updateTask(prevTask, true);
        setTimeout(async function() {
          await vm.updateTasks();
        }, 1000);
      }
    },

    async taskDown(currentTask) {
      const currTaskIndex = this.tasks.findIndex((task) => task._id == currentTask._id);
      const nextTaskIndex = currTaskIndex + 1;
      const nextTask = this.tasks[nextTaskIndex];
      const vm = this;

      if (nextTask) {
        const currNumQueue = currentTask.num_in_queue;
        const nextNumQueue = nextTask.num_in_queue;

        if (currNumQueue == nextNumQueue) {
          currentTask.num_in_queue += 1;
        } else {
          currentTask.num_in_queue = nextNumQueue;
          nextTask.num_in_queue = currNumQueue;
        }

        const editTaskComponent = vm.$refs['EditTaskComponent_' + currentTask._id][0];
        await editTaskComponent.updateTask(currentTask, true);
        await editTaskComponent.updateTask(nextTask, true);
        setTimeout(async function() {
          await vm.updateTasks();
        }, 1000);
      }
    },

    clean() {
      this.taskCreating = false;   
      this.courses = [];
      this.tasks = [];
    },

    async init() {
      let vm = this;
      let courses = await CourseService.getAll('_id, name');
      courses.forEach((newValue, index) => {
        this.$set(this.courses, index, {id: newValue._id, name: newValue.name});
      });

      const day = await DayService.get(this.$route.params.id);
      this.day.id = this.$route.params.id;
      this.day.name = day.name;
      if (day.image) {
        let day_images = await Object.assign({}, day.image, {'src': 'data:' + day.image.contentType + ';base64,' + day.image.data});
        await vm.$nextTick();
        vm.day_images = [];
        vm.day_images.push(day_images);
      }

      let selectedCourse = this.courses.find(item => {
          return item.id === day.course_id;
      });
      this.selectedCourse = Object.assign({}, this.selectedCourse, { id: selectedCourse.id, name: selectedCourse.name });
      this.selectedStatus = this.statuses.find((status) => status.id == day.status);

      day.tasks.forEach((task, index) => {
        this.$set(this.tasks, index, task);
      });
    }
  }
};
</script>

<style scoped>
</style>
